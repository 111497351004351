<template>
    <section class="pt-4" >
        <div class="px-4 custom-scroll overflow-auto" style="height:calc(100vh - 218px)">
            <cargando v-if="cargando" />
            <div class="row mx-0">
                <p class="col-12 text-black f-600 f-28">
                    Carrito de compras
                </p>
                <div class="like-bar ml-3 mt-1" />
            </div>
            <div class="row mx-0 py-3" />
            <div v-if="!showCarrito" class="row h-100 justify-content-center align-items-center">
                <div class=" py-3 px-4 mr-3 mb-3 text-center">
                    <img :src="funImagenGeneral(41)" width="250" style="max-height:100%;" />
                    <div class=" py-3 px-4 mr-3 text-center">
                        <strong>  Tu carrito está vacio </strong>
                        <div>
                            Parece que aún no has añadido nada.
                        </div>
                    </div>
                    <div class="row mx-0 mt-xl-4 mt-lg-4 mt-md-4 mt-sm-3 mt-2 mt-2 mb-md-2">
                        <div class="col-12 px-2">
                            <div class="btn-general" @click="$router.push({name:'invitado'})">
                                Ir al catalogo
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row mx-0">
                <div class="col-xl-5 co-lg-5 col-md-6 col-sm-12 col-12">
                    <p class="text-general f-24 f-600 mb-3">
                        Productos
                    </p>
                    <div class="row mx-0">
                        <div class="col-xl-8 col-lg-10 col-md-12 col-sm-12 col-12 px-0">
                            <invitado-agregar-producto 
                            v-for="(data, index) in listaProductos" 
                            :key="index" 
                            :producto="data" 
                            @remover="vaciarCarrito('noCerrar')" 
                            @actualizar="actualizarCarrito"
                            @resumen="actualizarResumen" 
                            />
                        </div>
                    </div>
                </div>
                <div class="col-xl-5 co-lg-5 col-md-6 col-sm-12 col-12">
                    <p class="text-general f-24 f-600 mb-3">
                        Resumen
                    </p>
                    <div class="box-resumen bg-white br-8 p-3">
                        <div class="btn-cupon mb-2 cr-pointer" @click="verCupon">
                            <i class="icon-coupon f-17 mr-3" /> ¿Tienes un cupón?
                        </div>
                        <!-- <div class="btn-recompensas cr-pointer" @click="reclamarRecompensa">
							<i class="icon-gem f-17 mr-2" />
							Canjea tus recompensas
						</div> -->
                        <div class="row mx-0 mt-3 align-items-center">
                            <i class="icon-order f-22" />
                            <div class="col-auto px-2 text-general f-600">
                                Detalle del cobro
                            </div>
                        </div>
                        <div class="row mx-0 f-15">
                            <div class="col-auto text-general pl-4 pr-0">
                                Productos ({{ resumen.cantidad_productos }}) ({{ resumen.cantidad_unidades }} Und)
                            </div>
                            <div class="col-auto text-general f-600 ml-auto">
                                {{ formatNumero(resumen.total_productos, 0, true) }}
                            </div>
                        </div>
                        <div class="row mx-0 f-15 mt-2">
                            <div class="col-auto text-general pl-4 pr-0">
                                Descuento Promos
                            </div>
                            <div class="col-auto text-red f-600 ml-auto">
                                {{ formatNumero(resumen.descuentos, 0, true) }}
                            </div>
                        </div>
                        <div class="row mx-0 f-15 mt-2">
                            <div class="col-auto text-general f-600 pl-4 pr-0">
                                SUB-TOTAL
                            </div>
                            <div class="col-auto text-general f-600 ml-auto">
                                {{ formatNumero(resumen.total, 0, true) }}
                            </div>
                        </div>
                        <div class="row mx-0 py-3" />
                        <div class="row mx-0 f-15 mt-2">
                            <div class="col-auto text-general pl-4 pr-0">
                                Domicilio
                            </div>
                            <div class="col-auto text-red f-600 ml-auto">
                                Gratis
                            </div>
                        </div>
                        <hr class="bg-gris ml-4 mr-3" />
                        <div class="row mx-0 f-15 mt-2">
                            <div class="col-auto text-general pl-4 pr-0 f-600">
                                TOTAL
                            </div>
                            <div class="col-auto text-general f-600 ml-auto">
                                {{ formatNumero(resumen.total, 0, true) }}
                            </div>
                        </div>
                        <div class="row mx-0 py-4" />
                        <div class="btn-outline bg-general text-white mt-2 mx-3 f-15" style="height:44px;" @click="irRegistrarse">
                            Regístrate para continuar
                        </div>
                        <div class="btn-outline mx-3 mt-3" @click="confirmarVaciarCarrito">
                            Vaciar carrito
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal ref="modalVaciarCarrito" titulo="Vaciar carrito" no-aceptar adicional="Vaciar" tamano="moda-sm" @adicional="vaciarCarrito('cerrar')">
            <div class="row mx-0 j-center my-3">
                <div class="col-auto">
                    ¿Desea vaciarlo?
                </div>
            </div>
        </modal>
        <invitado-modal-registrarse ref="modalRegistrarse" />
        <section-footer class="mt-3 mt-lg-0" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Service from '~/services/invitado/invitado'
import Cargando from '../../components/cargando.vue'
export default {
    components: {
        Cargando
    },
    data(){
        return {
            cargando: false,
            showCarrito: false,
            prodInsuficiente: false,
            listaProductos: [],
            cant_carrito: 15,
            resumen: {
                cantidad_productos: 0,
                total_productos: 0,
                descuentos: 0,
                impuestos: 0,
                total: 0,
            },
        }
    },
    computed: {
        ...mapGetters({
            carrito: 'invitado/carrito',
        }),
        tienda(){
            return JSON.parse(localStorage.getItem('tienda'));
        },
    },
    mounted(){
        if(this.carrito.length < 1){
            if(JSON.parse(localStorage.getItem('carritoInvitado'))){
                this.$store.commit('invitado/obtenerCarrito', JSON.parse(localStorage.getItem('carritoInvitado')));
            }
        }
        this.getProductosCarrito();
    },
    methods: {
        async getProductosCarrito(){
            try {
                if(this.carrito.length < 1)return;
                //this.cargando = true;
                if(!this.tienda.id_tienda){
                    await this.$store.dispatch('invitado/getTiendaDefault')
                }
                const params = {
                    idTienda: this.tienda.id_tienda,
                    carrito: JSON.stringify(this.carrito)
                };
                const { data } = await Service.getProductosCarrito(params);
                this.listaProductos = data.productos;
                this.resumen = data.resumen;
                this.showCarrito = true;
            } catch(e){
                this.errorCatch(e)
            } finally{
                this.cargando = false;
            }
        },
        confirmarVaciarCarrito(){
            this.$refs.modalVaciarCarrito.toggle();
        },
        vaciarCarrito(bandera){
            localStorage.removeItem('carritoInvitado');
            this.$store.commit('invitado/obtenerCarrito', []);
            this.showCarrito = false;
            this.listaProductos = [];
            this.resumen.cantidad_productos = 0;
            this.resumen.total_productos = 0;
            this.resumen.descuentos = 0;
            this.resumen.impuestos = 0;
            this.resumen.total = 0;
            if(bandera == 'cerrar'){
                this.$refs.modalVaciarCarrito.toggle();
            }
        },
        actualizarCarrito(){
            this.listaProductos = [];
            this.getProductosCarrito();
        },
        actualizarResumen(){
            this.getProductosCarrito();
        },
        verCupon(){
            this.$refs.modalRegistrarse.toggle();
        },
        reclamarRecompensa(){
            this.$refs.modalCanjear.toggle();
        },
        irRegistrarse(){
            this.$router.push({name: 'registro'});
        }
    }
}
</script>
<style lang="scss" scoped>
.box-resumen{
    box-shadow: 0px 3px 6px #0000000D;
    .btn-cupon{
        height: 44px;
        display: flex;
        align-items: center;
        color: #612AD5;
        justify-content: center;
        background-color: #E1DBEF;
        border-radius: 8px;
    }
    .btn-recompensas{
        height: 44px;
        display: flex;
        align-items: center;
        color: #29D884;
        justify-content: center;
        background-color: #DBF9EA;
        border-radius: 8px;
    }
}

.icon-handshake::before{
    margin-left: 0px !important;
}
@media (max-width: 768px){
    section.custom-scroll.overflow-auto{
        //height: calc(100vh - 150px) !important;
        height: calc(100vh - 170px) !important;
    }
}
</style>